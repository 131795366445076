import React, { useEffect, useState } from 'react';
import { NextPageContext } from 'next';

import initializePageParams from 'src/page-params-initializer';
import dynamic from 'next/dynamic';
import Searchform from 'modules/searchform';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Offerlist from 'modules/offerlist';
import { OffersProvider } from 'providers/offers';
import useTracking from 'providers/tracking/use-tracking';
import PageHeader from 'modules/page-header';
import { browserSide } from 'src/utils';
import useHasMounted from 'hooks/use-has-mounted';
import useBanners from 'providers/banners/use-banners-context';
import Banner from 'components/banner';

const Page = (props) => {
  const hasMounted = useHasMounted();
  const { triggerSearch, triggerPricelist } = useTracking();
  const { subpage, general, agent } = useCarsApi();
  const [searchModified, setSearchModified] = useState(false);
  const { search } = props;
  const [CustomComponentsList] = useState({
    BahnDeAgentSwitch: null
  });

  const { banners, getBanners } = useBanners();

  if (['bahndecar', 'bahndebahncardcar'].includes(agent?.agentId)) {
    if (!CustomComponentsList.BahnDeAgentSwitch) {
      CustomComponentsList.BahnDeAgentSwitch = dynamic(
        () => import('components/custom-agent-components/bahnde-agent-switch'),
        { ssr: false }
      );
    }
  }

  useEffect(() => {
    if (subpage === 'searchform') {
      triggerSearch({
        language: search?.locale
      });
      getBanners({
        agent: agent.agentId,
        IBEStep: 'search'
      });
    } else {
      triggerPricelist({
        language: search?.locale,
        currency: general?.currency,
        pickup_date: search?.dates?.from,
        pickup_place: search?.location?.pickUp?.locationKey,
        rental_days: search?.rentDays,
        return_date: search?.dates?.to,
        return_place: search?.location?.dropOff?.locationKey ?? ''
      });
    }
  }, [subpage]);

  return (
    <>
      <OffersProvider agent={agent} general={general}>
        <div className="index__custom-components">
          {hasMounted && CustomComponentsList.BahnDeAgentSwitch && browserSide() && (
            <CustomComponentsList.BahnDeAgentSwitch />
          )}
        </div>
        <PageHeader />
        <>
          {banners && <Banner banners={banners} alignment="top" />}
          <Searchform search={search} isSearchModified={setSearchModified} />
          {banners && <Banner banners={banners} alignment="left" />}
          {banners && <Banner banners={banners} alignment="right" />}
          {subpage === 'offerlist' && !searchModified && <Offerlist />}
          {banners && <Banner banners={banners} alignment="bottom" />}
        </>
      </OffersProvider>
    </>
  );
};

export const getServerSideProps = async (ctx: NextPageContext): Promise<any> => ({
  props: {
    ...(await initializePageParams({
      ctx,
      subpage: 'searchform',
      languageNamespaces: [
        'all_pages',
        'car_details',
        'search_form',
        '2_page',
        'misc',
        'charges',
        'filters',
        'insurances',
        'partners',
        'date_and_time'
      ]
    }).catch(() => null))
  }
});

export default Page;
