import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './banner.module.scss';

type BannerAlignmentType = 'top' | 'bottom' | 'left' | 'right';

type BannerType = {
  id?: number;
  url?: string;
  alt?: string;
  link?: string;
  additionalText?: string;
  IBEStep?: Array<string>;
  alignment: BannerAlignmentType;
};

type Props = {
  banners: Array<BannerType>;
  alignment: BannerAlignmentType;
};

const LinkWrapper = ({ condition, wrapper, children }) =>
  condition === true ? wrapper(children) : children;

const Banner: FC<Props> = ({ banners = [], alignment = 'top' }) => {
  if (!banners.length) return <></>;
  return (
    <>
      {banners.map(
        (banner, i) =>
          banner?.url &&
          banner.alignment === alignment && (
            <div
              key={i}
              className={classNames('banner_container', styles.banner__container, {
                [styles['banner__container__left']]:
                  banner.alignment === 'left' && alignment === 'left',
                banner__container__left: banner.alignment === 'left' && alignment === 'left',
                [styles['banner__container__right']]:
                  banner.alignment === 'right' && alignment === 'right',
                banner__container__right: banner.alignment === 'right' && alignment === 'right'
              })}
            >
              <div
                className={classNames('banner_single', styles.banner__single, {
                  [styles['banner__single__side']]:
                    banner.alignment === 'left' || banner.alignment === 'right'
                })}
              >
                <LinkWrapper
                  condition={banner?.link.length > 0}
                  wrapper={(children) => (
                    <a href={banner?.link} target="_blank">
                      {children}
                    </a>
                  )}
                >
                  <img
                    src={banner.url}
                    alt={banner?.alt || banner.url}
                    className={classNames('banner_image', styles.banner__image)}
                  />
                  {banner?.additionalText && (
                    <span
                      className={classNames(
                        'banner_additional_text',
                        styles.banner__additionalText
                      )}
                    >
                      {banner.additionalText}
                    </span>
                  )}
                </LinkWrapper>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default Banner;
